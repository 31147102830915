import { Maybe } from '@graphql-tools/utils'
import { add, isAfter } from 'date-fns'
import { describePromoOffer } from 'logic/promo/describePromoOffer'
import { describePlanGroup } from 'logic/subscription/group/describe'
import { groupActiveSubscriptions } from 'logic/subscription/plan/my/group/groupActiveSubscriptions'
import { planSpecs } from 'logic/subscription/plan/spec'
import { PlanGroup, PlanType } from 'logic/subscription/plan/types'
import { bound } from 'utils/class'
import { formatCentsWithDollarSign } from 'utils/format'
import { ident, isOneOf } from 'utils/general'
import { EstatePlanningPriceType } from 'utils/getInitialEstatePlanningPrice'
import { ExtendedPromotion } from 'utils/promo'
import { offerSpecs } from 'utils/promo/spec'
import { ApiType } from 'utils/types'
import { INFINITE_PROMO_CODE_EXPIRATION } from 'logic/promo/describe'

export function describeMyPlansForPlanGroup(userMe: ApiType['UserResponse'], planGroup: PlanGroup) {
  return bound({
    getSubscription() {
      return groupActiveSubscriptions(userMe)[planGroup]
    },
    hasFreePlan() {
      return !!this.getActivePlanSpec().isFree
    },
    hasPaidPlan() {
      return !this.hasFreePlan()
    },
    isGroupLife: () => planGroup === 'GROUP_LIFE',
    isDowngrading() {
      return this.isSubscriptionBeingCancelled() || this.isSubscriptionBeingDowngraded()
    },

    isPeriodLongerThan(duration: Duration) {
      const sub = this.getSubscription()
      const period_start = new Date(sub?.current_period_start ?? '').getTime()
      const period_end = new Date(sub?.current_period_end ?? '').getTime()

      if (Number.isNaN(period_start) || Number.isNaN(period_end)) return false

      return isAfter(period_end, add(period_start, duration))
    },

    isSubscriptionBeingDowngraded() {
      return isOneOf(this.getSubscription()?.state, ['IN_CANCELLATION', 'IN_DOWNGRADE'])
    },

    isSubscriptionBeingCancelled() {
      return this.getSubscription()?.state === 'IN_CANCELLATION'
    },

    describeActiveDowngrade() {
      const sub = this.getSubscription()
      return this.isSubscriptionBeingCancelled()
        ? {
            type: 'cancel' as const,
            targetPlan: ident<PlanType>(describePlanGroup(planGroup).getFreePlanType()),
            at: sub?.current_period_end,
          }
        : this.isSubscriptionBeingDowngraded()
        ? {
            type: 'downgrade' as const,
            at: sub?.current_period_end,
          }
        : {
            type: 'no_downgrade' as const,
          }
    },

    hasLifetimePlan() {
      const activePlan = this.getSubscription()
      if (!activePlan) return false
      if (activePlan.state !== 'ACTIVE') return false
      return (
        activePlan.type === 'LIFETIME' ||
        activePlan.current_period_end == null ||
        isAfter(new Date(activePlan.current_period_end), INFINITE_PROMO_CODE_EXPIRATION)
      )
    },

    getActivePlan(): PlanType {
      return this.getSubscription()?.type ?? describePlanGroup(planGroup).getFreePlanType()
    },

    getActivePlanSpec() {
      return planSpecs[this.getActivePlan()]
    },
    getEstatePlanningPriceForUser(
      estatePlanningPrice: EstatePlanningPriceType,
      isLogged: boolean,
      promotion?: ExtendedPromotion
    ) {
      const currentSubs = this.getSubscription()

      const isUserOnActiveEstatePlanningPlan = currentSubs?.type === 'ESTATE_PLANNING'

      const estatePlanningPriceForUser = isUserOnActiveEstatePlanningPlan
        ? {
            type: 'recurring' as const,
            undiscountedPriceText: formatCentsWithDollarSign(estatePlanningPrice.recurrent_price),
          }
        : this.getEstatePlanningPriceWithPromotion(estatePlanningPrice, isLogged, promotion)
      return estatePlanningPriceForUser
    },
    getEstatePlanningPriceWithPromotion(
      estatePlanningPrice: EstatePlanningPriceType,
      isLogged: boolean,
      promotion?: ExtendedPromotion
    ) {
      const loggedInUserHasClaimed =
        isLogged &&
        promotion &&
        !promotion?.canBeClaimed &&
        promotion?.cannotClaimReason === 'already_claimed' &&
        !promotion?.isExpiredForRedeeming

      const nonLoggedInUserCanClaim =
        promotion && promotion.canBeClaimed && !promotion.isExpiredForRedeeming

      if (!loggedInUserHasClaimed && !nonLoggedInUserCanClaim) {
        return {
          undiscountedPriceText: formatCentsWithDollarSign(estatePlanningPrice.initial_price),
          type: 'initial-no-discount' as const,
        }
      }
      const freeSubCtx = this.getEstatePlanFreeSubscriptionContext(promotion)
      if (freeSubCtx) {
        return {
          ...freeSubCtx,
          undiscountedPriceText: formatCentsWithDollarSign(estatePlanningPrice.initial_price),
        }
      }
      const discountedPriceCtx = this.getDiscountedPriceForEstatePlanWithPromotion(
        estatePlanningPrice.initial_price,
        promotion
      )
      if (discountedPriceCtx) {
        return {
          ...discountedPriceCtx,
          undiscountedPriceText: formatCentsWithDollarSign(estatePlanningPrice.initial_price),
        }
      }
      return {
        type: 'initial-no-discount' as const,
        undiscountedPriceText: formatCentsWithDollarSign(estatePlanningPrice.initial_price),
      }
    },
    getDiscountedPriceForEstatePlanWithPromotion(
      initialPrice: Maybe<number>,
      promotion: ExtendedPromotion
    ) {
      if (!initialPrice) return null

      const { discountOffers } = promotion
      const discountedOffer = discountOffers.filter(
        (offer) =>
          offer.discount_types?.includes('ALL') || offer.discount_types?.includes('ESTATE_PLAN')
      )
      if (!discountedOffer.length) return null

      const discountedPrices = discountedOffer.map(
        (offer) => describePromoOffer(offer).getDiscountedPrice(initialPrice) ?? initialPrice
      )
      const minPrice = Math.min(...discountedPrices, initialPrice)
      if (minPrice === initialPrice) return null

      return {
        type: 'initial-discount' as const,
        discountedText: formatCentsWithDollarSign(minPrice),
      }
    },
    getEstatePlanFreeSubscriptionContext(promotion: ExtendedPromotion): Maybe<{
      type: 'initial-free-subscription'
    }> {
      const { freeSubOffers } = promotion
      const freeOffers = freeSubOffers.filter(
        (offer) => offer.type && offerSpecs[offer.type].freeSubscription === 'ESTATE_PLANNING'
      )
      const freeOfferExists = freeOffers.some((offer) => offer.amount !== 0)

      if (!freeOffers.length || !freeOfferExists) return null
      return {
        type: 'initial-free-subscription',
      }
    },
  })
}
